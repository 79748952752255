<template>
   <div>
      <div class="mt-3">
         <div class="container" id="myTabContent">
            <div class="modal-body">
               <div class="row gutters">
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                     <div class="form-group">
                        <label class="required">{{ t('CONFIG_CLICK_SIGN.RESPONSIBLE') }}:</label>
                        <Select2 id="responsible" v-model="iten.responsible_id" :placeholder="iten.responsible_name"
                                 v-if="iten.responsible_name" :disabled="isView"
                                 :settings="ajaxPerson"></Select2>
                        <Select2 id="responsible" v-model="iten.responsible_id" placeholder="Selecione"
                                 v-else :disabled="isView"
                                 :settings="ajaxPerson"></Select2>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label for="deadline_to_signature" :class="isEdit? 'required': ''">{{ t('CONFIG_CLICK_SIGN.DEADLINE_TO_SIGNATURE') }}:</label>
                        <input type="number" class="form-control" id="deadline_to_signature" placeholder="Dias para expiração" max="30"
                               @keyup="checkMaxNumber"
                               v-model="iten.deadline_to_signature" :disabled="isView">
                     </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="form-group">
                        <label for="exampleFormControlTextarea1" :class="isEdit? 'required': ''">Mensagem de Solicitação de Assinatura</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                  v-model="iten.message" placeholder="Para concluir nossa negociação, por favor leia atentamente e assine o documento que estamos encaminhando." :disabled="isView"></textarea>
                     </div>
                  </div>
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                   <div class="custom-control custom-switch">
                     <input type="checkbox" class="custom-control-input" id="realtor_sign"
                     v-model="iten.realtor_sign" :disabled="isView" checked="true">
                     <label  class="custom-control-label" for="realtor_sign">{{ t('CONFIG_CLICK_SIGN.REALTOR_SIGN') }}</label>
                   </div>
                 </div>
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                   <div class="custom-control custom-switch">
                     <input type="checkbox" class="custom-control-input" id="handwritten_enabled"
                            v-model="iten.handwritten_enabled" :disabled="isView">
                     <label  class="custom-control-label" for="handwritten_enabled">{{ t('CONFIG_CLICK_SIGN.HANDWIRITTEN_ENABLED') }}</label>
                   </div>
                 </div>
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                   <div class="custom-control custom-switch">
                     <input type="checkbox" class="custom-control-input" id="selfie_enabled"
                            v-model="iten.selfie_enabled" :disabled="isView">
                     <label class="custom-control-label" for="selfie_enabled">{{ t('CONFIG_CLICK_SIGN.SELFIE_ENABLED') }}</label>
                   </div>
                 </div>
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="custom-control custom-switch">
                       <input type="checkbox" class="custom-control-input" id="official_document_enabled"
                               v-model="iten.official_document_enabled" :disabled="isView">
                       <label  class="custom-control-label" for="official_document_enabled">{{ t('CONFIG_CLICK_SIGN.OFFICIAL_DOCUMENT_ENABLED') }}</label>
                     </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="liveness_enabled"
                               v-model="iten.liveness_enabled" :disabled="isView">
                        <label  class="custom-control-label" for="liveness_enabled">{{ t('CONFIG_CLICK_SIGN.LIVENESS_ENABLED') }}</label>
                     </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="facial_biometrics_enabled"
                               v-model="iten.facial_biometrics_enabled" :disabled="isView">
                        <label  class="custom-control-label" for="facial_biometrics_enabled">{{ t('CONFIG_CLICK_SIGN.FACIAL_BIOMETRICS_ENABLED') }}</label>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer" v-if="!isView">
               <div class="btn btn-primary" @click="createConfig">
                  {{t('GENERAL.SAVE')}}
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Select2 from 'vue3-select2-component';
import ConfigClickSign from '../../services/ConfigClickSign';
import errorMsg from '../../components/errorMsg';

export default {
   name: "createConfigClickSignComponet",
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   props:{
      itenId: {
         required: false,
         type: Number
      },
      isEdit: {
         type: Boolean,
         default: false,
      },
      isView: {
         type: Boolean,
         default: false,
      }
   },
   mounted() {
      if (this.itenId){
         this.$store.commit('changeLoading', true);
         ConfigClickSign.view(this.itenId).then((resp) => {
            this.iten = resp.data;
            this.$store.commit('changeLoading', false);
         }).catch(() => {
            this.$store.commit('changeLoading', false);
         })
      }
   },
   data() {
      return {
         iten: {},
         ajaxPerson: {
            width: '100%',
            language: {
               inputTooShort: function () {
                  return "Por favor, insira ao menos 1 caracteres para pesquisar.";
               },
               noResults: function () {
                  return "Nenhum resultado encontrado!";
               }
            },
            minimumInputLength: 1,
            ajax: {
               url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list`,
               dataType: "json",
               headers: {
                  'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                  'company-id': localStorage.getItem('companyId'),
               },
               data: params => {
                  return {
                     term: params.term,
                  };
               },
               processResults: function (data) {
                  if (!(data.validations)) {
                     data.unshift({id: 0, text: "Nenhum"});
                     return {
                        results: data.map((i) => ({id: i.id, text: i.name}))
                     };
                  } else {
                     return {results: {id: 0, text: "Nenhum"}};
                  }
               },
               cache: true
            },
            initSelection: function (element, callback) {
               callback({id: 1, text: 'initSelection test'});
            },
         },

      }
   },
   components: {
      Select2,
   },
   methods: {
      createConfig() {
         if (!this.iten.responsible_id){
            this.toast.error('Preencha todos os campos obrigatorios');
            return;
         }
         this.$store.commit('changeLoading', true);
         if (this.isEdit){
            if (!this.iten.deadline_to_signature || !this.iten.message?.length){
               this.toast.error('Preencha todos os campos obrigatorios');
               this.$store.commit('changeLoading', false);
               return;
            }
            ConfigClickSign.edit(this.iten).then(() => {
               this.$emit('created');
               this.toast.success('Configuração editada com sucesso!');
               document.getElementById('closeX')?.click();
            }).catch(err => {
               this.$store.commit('changeLoading', false);
               errorMsg.errorMsg(err);
            })
            return;
         }
         this.iten.company_id = localStorage.getItem('companyId');
         !this.iten?.deadline_to_signature && delete this.iten.deadline_to_signature;
         !this.iten?.message && delete this.iten.message;
         ConfigClickSign.create(this.iten).then(() => {
            this.$emit('created');
            this.toast.success('Configuração criada com sucesso!');
            document.getElementById('closeX')?.click();
         }).catch(err => {
            this.$store.commit('changeLoading', false);
            errorMsg.errorMsg(err);
         })
      },
      checkMaxNumber(){
         if (this.iten.deadline_to_signature > 30){
            this.iten.deadline_to_signature = 30;
         }else if (this.iten.deadline_to_signature <= 0){
            this.iten.deadline_to_signature = 1;
         }
      }
   }
}
</script>

<style scoped>

</style>